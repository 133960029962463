import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PoemFruehlingExplained from "../../../../../content/kurse/jahreszeiten/01-jahreszeitengedicht/poem-fruehling-explained"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import PaperStack from "../../../../../components/paper-stack"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/strophen-und-zeilen"
        />
      }
    >
      <Seo title="Der Frühling" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Das Gedicht lesen
          </Heading>
        </Stack>

        <TaskSidebar
          main={
            <PaperStack>
              <PoemFruehlingExplained />
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Lies dir das Gedicht zunächst einmal durch. Besonders schwierige
              Wörter haben wir unterstrichen. Du kannst sie anklicken und
              erhältst eine kurze Erklärung.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
